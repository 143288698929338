<template>
  <div>
    <v-dialog v-model="dialog" persistent>
      <v-card :key="refresh">
        <v-card-title>
          <span class="headline">Pharmacist Search</span>
        </v-card-title>
        <v-card-text class="text-xs-left px-5">
          <v-container grid-list-md>
               <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
            <!-- <v-layout wrap>
              <v-flex xs12 md4>
                <v-text-field
                  label="Pharmacist Name"
                  v-model="pharmName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field label="OCP" v-model="ocp"></v-text-field>
              </v-flex>
            </v-layout> -->
          </v-container>
          <!-- <small>*Search by either</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.native="dialog = false">close</v-btn>
          <v-btn color="primary" @click.native="searchPharmacist">Search</v-btn>
        </v-card-actions>
      </v-card>

      <v-data-table
        v-show="pharmacistList.length"
        v-model="selectedPharmacist"
        hide-default-footer
        :items="pharmacistList"
        item-key="OCP"
        :headers="headers"
        :page.sync="page"
             :search="search"
        @page-count="pageCount = $event"
      >
        <template slot="headers">
          <tr>
            <th class="black--text" width="50%">Pharmacist Name</th>
            <th class="black--text" width="50%" align="right">OCP</th>
          </tr>
        </template>

        <template v-slot:item="props">
          <tr :active="props.selected" @click="selected(props)">
            <td width="50%" align="left">{{ props.item[`UserName`] }}</td>
            <td width="50%" align="left">
              <strong>{{ props.item[`PharmID`] }}</strong>
            </td>
          </tr>
        </template>

        <template slot="no-data">
          <tr>
            <td colspan="6" class="pa-4">
              No pharmacists to select from
            </td>
          </tr>
        </template>
      </v-data-table>
              <v-card>
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 100)"
          ></v-text-field>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    refreshNum: {
      required: false,
    },
  },
  watch: {
    refresh: function() {
      this.searchPharmacist();
    },
  },
  data() {
    return {
      search: '',
      dialog: false,
      page: 1,
      pageCount: length,
      itemsPerPage: 10,
      refresh: true,
      pharmName: "",
      ocp: "",
      pharmacistList: [],
      selectedPharmacist: [],
      onPharmacistSelection: () => {},
      headers: [
        {
          text: "Pharmacist Name",
          align: "left",
          value: "UserName",
          sortable: false,
        },
        { text: "OCP #", align: "left", value: "PharmID", sortable: false },
      ],
    };
  },
  created() {
    if (!window.searchPharmacist) {
      window.searchPharmacist = {};
    }

    // FIXME expose store to window and allow dispatching this action
    window.searchPharmacist.open = (callbackPharmacistSelection) => {
      this.dialog = true;
      this.refresh++;
      // set the defined callback
      this.onPharmacistSelection = callbackPharmacistSelection;
    };

    window.searchPharmacist.close = () => {
      this.dialog = false;
    };

    // this.searchPharmacist();
  },
  methods: {
    selected(pharmacist) {
      // fire the callback
      this.onPharmacistSelection(pharmacist.item);
      // close the modal
      this.dialog = false;
    },
    async searchPharmacist() {
      this.pharmacistList =
        (await this.$root.$system.DBAdapterConn.getSearchbyPharmacist(
          this.pharmName
        )) || [];
    },
  },
  computed: {
    ...mapGetters(["PatientProfile"]),
  },
};
</script>

<style scoped></style>
